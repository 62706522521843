.other-sns {
  margin-top: 10px;
}

.other-sns ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -10px -30px 0;
}

@media screen and (max-width: 1139px) {
  .other-sns ul {
    margin: -10px -10px 0;
  }
}

.other-sns ul li {
  width: calc(33.3% - 60px);
  margin: 10px 30px 0;
  border-bottom: 1px solid #e5e5e5;
}

@media screen and (max-width: 1139px) {
  .other-sns ul li {
    width: calc(50% - 20px);
    margin: 10px 10px 0;
  }
}

.other-sns ul li a {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px;
}

.other-sns ul li a::before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media print, screen and (min-width: 1140px) {
  .is-view-pc .other-sns ul li a:hover {
    color: #ee1b2d;
  }
}

.other-sns__twitter a::before {
  width: 27px;
  height: 21px;
  background-image: url("/assets/images/ico_twitter_02.png");
}

@media screen and (max-width: 1139px) {
  .other-sns__twitter a::before {
    width: 25px;
    height: 20px;
  }
}

.other-sns__instagram a::before {
  width: 30px;
  height: 30px;
  background-image: url("/assets/images/ico_instagram.png");
}

@media screen and (max-width: 1139px) {
  .other-sns__instagram a::before {
    width: 24px;
    height: 24px;
  }
}

.other-sns__youtube a::before {
  width: 31px;
  height: 22px;
  background-image: url("/assets/images/ico_youtube.png");
}

@media screen and (max-width: 1139px) {
  .other-sns__youtube a::before {
    width: 28px;
    height: 20px;
  }
}

.other-sns__facebook a::before {
  width: 30px;
  height: 30px;
  background-image: url("/assets/images/ico_facebook.png");
}

@media screen and (max-width: 1139px) {
  .other-sns__facebook a::before {
    width: 25px;
    height: 25px;
  }
}

.notfound__ttl {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 148px;
  text-align: center;
  line-height: 1;
  margin-bottom: 1em;
  position: relative;
}

@media screen and (max-width: 1139px) {
  .notfound__ttl {
    height: 122px;
  }
}

.notfound__ttl::before {
  content: "";
  display: block;
  width: 148px;
  height: 148px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("/assets/images/ico_search_red.svg") no-repeat center/cover;
  opacity: 0.2;
  margin-left: 10px;
}

@media screen and (max-width: 1139px) {
  .notfound__ttl::before {
    width: 122px;
    height: 122px;
  }
}

.notfound__ttl b {
  font-size: 8rem;
  font-weight: 700;
  display: block;
  color: #ee1b2d;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 1139px) {
  .notfound__ttl b {
    font-size: 6.8rem;
  }
}

.notfound__ttl span {
  font-size: 3rem;
  font-weight: 700;
  display: block;
  margin-top: 0.2em;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 1139px) {
  .notfound__ttl span {
    font-size: 2.5rem;
  }
}

.notfound__txt {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 3em;
}

@media screen and (max-width: 1139px) {
  .notfound__txt {
    font-size: 1.4rem;
  }
}

.notfound__txt b {
  font-size: 4rem;
  font-weight: 700;
  display: block;
  margin-bottom: 0.3em;
}

@media screen and (max-width: 1139px) {
  .notfound__txt b {
    font-size: 2rem;
  }
}

.maintenance__logo {
  width: 150px;
  margin: 0 auto;
}

@media screen and (max-width: 1139px) {
  .maintenance__logo {
    width: 120px;
  }
}

.maintenance__ttl {
  text-align: center;
  font-size: 4rem;
  font-weight: 700;
  display: block;
  margin-bottom: 0.3em;
}

@media screen and (max-width: 1139px) {
  .maintenance__ttl {
    font-size: 2rem;
  }
}

.maintenance__txt {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 3em;
}

@media screen and (max-width: 1139px) {
  .maintenance__txt {
    font-size: 1.4rem;
    margin-bottom: 2em;
  }
}

.search .gsc-control-cse {
  padding: 0;
}

.search .gsc-selected-option-container {
  max-width: inherit;
}

.search .gsc-results .gsc-cursor-box {
  text-align: center;
}

.search .gsc-results .gsc-cursor-box .gsc-cursor-page {
  font-size: 1.6rem;
  display: inline-block;
  padding: 0.5em 0;
  width: 2.5rem;
  line-height: 1;
  color: #ee1b2d;
}

@media screen and (max-width: 1139px) {
  .search .gsc-results .gsc-cursor-box .gsc-cursor-page {
    width: 2rem;
    font-size: 1.2rem;
  }
}

.search .gsc-results .gsc-cursor-box .gsc-cursor-current-page {
  color: #000;
}

.search .gsc-webResult.gsc-result {
  border-bottom: 1px dotted #ccc;
}

.search .gs-web-image-box, .search .gs-promotion-image-box {
  margin: 2px 8px 2px 0;
}
