@charset "utf-8";

@import "foundation/_setting.scss";
//==========================================================================
//other.scss
//==========================================================================

//-----------------------------------------------------------------
//.other
//-----------------------------------------------------------------
.other {
  
}

.other-sns {
  margin-top: 10px;
  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: -10px -30px 0;
    @include mdMax {
      margin: -10px -10px 0;
    }
    li {
      width: calc(33.3% - 60px);
      margin: 10px 30px 0;
      border-bottom: 1px solid $colorBorder;
      @include mdMax {
        width: calc(50% - 20px);
        margin: 10px 10px 0;
      }
      a {
        display: flex;
        align-items: center;
        position: relative;
        padding: 10px;
        &::before {
          content: "";
          display: inline-block;
          margin-right: 10px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
        @include hover {
          color: $colorMain;
        }
      }
    }
  }
  &__twitter {
    a {
      &::before {
        width: 27px;
        height: 21px;
        background-image: url("/assets/images/ico_twitter_02.png");
        @include mdMax {
          width: 25px;
          height: 20px;
        }
      }
    }
  }
  &__instagram {
    a {
      &::before {
        width: 30px;
        height: 30px;
        background-image: url("/assets/images/ico_instagram.png");
        @include mdMax {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  &__youtube {
    a {
      &::before {
        width: 31px;
        height: 22px;
        background-image: url("/assets/images/ico_youtube.png");
        @include mdMax {
          width: 28px;
          height: 20px;
        }
      }
    }
  }
  &__facebook {
    a {
      &::before {
        width: 30px;
        height: 30px;
        background-image: url("/assets/images/ico_facebook.png");
        @include mdMax {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}


//-----------------------------------------------------------------
//.notfound
//-----------------------------------------------------------------
.notfound {
  &__ttl {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 148px;
    text-align: center;
    line-height: 1;
    margin-bottom: 1em;
    position: relative;
    @include mdMax {
      height: 122px;
    }
    &::before {
      content: "";
      display: block;
      width: 148px;
      height: 148px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: url("/assets/images/ico_search_red.svg") no-repeat center / cover;
      opacity: 0.2;
      margin-left: 10px;
      @include mdMax {
        width: 122px;
        height: 122px;
      }
    }
    b {
      font-size: 8rem;
      font-weight: 700;
      display: block;
      color: $colorMain;
      position: relative;
      z-index: 2;
      @include mdMax {
        font-size: 6.8rem;
      }
    }
    span {
      font-size: 3rem;
      font-weight: 700;
      display: block;
      margin-top: 0.2em;
      position: relative;
      z-index: 2;
      @include mdMax {
        font-size: 2.5rem;
      }
    }
  }
  &__txt {
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 3em;
    @include mdMax {
      font-size: 1.4rem;
    }
    b {
      font-size: 4rem;
      font-weight: 700;
      display: block;
      margin-bottom: 0.3em;
      @include mdMax {
        font-size: 2rem;
      }
    }
  }
}


//-----------------------------------------------------------------
//.maintenance
//-----------------------------------------------------------------
.maintenance {
  &__logo {
    width: 150px;
    margin: 0 auto;
    @include mdMax {
      width: 120px;
    }
  }
  &__ttl {
    text-align: center;
    font-size: 4rem;
    font-weight: 700;
    display: block;
    margin-bottom: 0.3em;
    @include mdMax {
      font-size: 2rem;
    }
  }
  &__txt {
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 3em;
    @include mdMax {
      font-size: 1.4rem;
      margin-bottom: 2em;
    }
  }
}

//-----------------------------------------------------------------
//.search
//-----------------------------------------------------------------
.search {
  .gsc-control-cse {
    padding: 0;
  }
  .gsc-selected-option-container {
    max-width: inherit;
  }
  .gsc-results .gsc-cursor-box {
    text-align: center;
  }
  .gsc-results .gsc-cursor-box .gsc-cursor-page {
    font-size: 1.6rem;
    display: inline-block;
    padding: 0.5em 0;
    width: 2.5rem;
    line-height: 1;
    color: $colorMain;
    @include mdMax {
      width: 2rem;
      font-size: 1.2rem;
    }
  }
  .gsc-results .gsc-cursor-box .gsc-cursor-current-page {
    color: $colorPlain;
  }
  .gsc-webResult.gsc-result {
    border-bottom: 1px dotted #ccc;
  }
  .gs-web-image-box, .gs-promotion-image-box {
    margin: 2px 8px 2px 0;
  }
}